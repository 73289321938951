<template>
	<div class="pd_bottom">
		<div class="pd_bottom_pc"
			><div
				class="pd_bottom_logo pointer"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img src="https://zy.metaera.media/assets/images/event/34.png" alt="" />
			</div>
			<div class="pd_bottom_time">
				<div class="pd_bottom_time_1">Polkadot Decoded Asia 2024</div>
				<div class="pd_bottom_time_2">Time： Sep 16 - Sep 17, 2024</div>
			</div>
			<div class="pd_bottom_box">
				<div class="pd_bottom_box_1">
					<div
						class="pd_bottom_box_1_1 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_about',
							})
						"
					>
						ABOUT
					</div>
					<div
						class="pd_bottom_box_1_2 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_speaker',
							})
						"
					>
						SPEAKER
					</div>
				</div>
				<div class="pd_bottom_box_2">
					<div
						class="pd_bottom_box_1_1 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_partner',
							})
						"
					>
						PARTNER
					</div>
					<div
						class="pd_bottom_box_1_2 pointer"
						@click="showUrl('https://lu.ma/hszom0hw')"
						>PARTICIPATE</div
					>
				</div>
				<div class="pd_bottom_box_line"></div>
				<div class="pd_bottom_box_3">
					<div class="pd_bottom_box_4_1">SUBSCRIBE TO NEWS</div>
					<div class="pd_bottom_box_3_img">
						<div
							class="pd_bottom_box_3_img_1 pointer"
							@click="showUrl('https://twitter.com/MetaEraHK')"
						>
							<img
								src="https://zy.metaera.media/assets/images/event/12.png"
								alt=""
							/>
						</div>
						<div
							class="pd_bottom_box_3_img_1 pointer"
							@click="showUrl('https://t.me/MetaEraHK')"
						>
							<img
								src="https://zy.metaera.media/assets/images/event/11.png"
								alt=""
							/>
						</div>
						<div
							class="pd_bottom_box_3_img_1 pointer"
							@click="showUrl('https://www.linkedin.com/company/95041236/')"
						>
							<img
								src="https://zy.metaera.media/assets/images/event/8.png"
								alt=""
							/>
						</div>
						<div
							class="pd_bottom_box_3_img_1 pointer"
							@click="showUrl('https://www.youtube.com/@MetaEraHK')"
						>
							<img
								src="https://zy.metaera.media/assets/images/event/50.png"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div class="pd_bottom_box_4">
					<div class="pd_bottom_box_4_1">CONTACT US</div>
					<div class="pd_bottom_box_4_1 pointer" @click="sendEmail">
						event@metaera.hk
					</div>
					<div class="pd_bottom_box_4_3"></div>
				</div>
			</div>
			<div class="pd_bottom_line"></div>
			<div class="pd_bottom_company"> Copyright © 2024. Made by MetaEra </div>
			<div class="pd_bottom_line"></div>
		</div>

		<div class="pd_bottom_mobile">
			<div
				class="pd_bottom_logo"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img src="https://zy.metaera.media/assets/images/event/34.png" alt="" />
			</div>
			<div class="pd_bottom_time">
				<div class="pd_bottom_time_1">Polkadot <br />Decoded Asia 2024</div>
				<div class="pd_bottom_time_2">Time： <br />Sep 16 - Sep 17, 2024</div>
			</div>
			<div class="pd_bottom_box_3">
				<div class="pd_bottom_box_4_1">SUBSCRIBE TO NEWS</div>
				<div class="pd_bottom_box_3_img">
					<div
						class="pd_bottom_box_3_img_1 pointer"
						@click="showUrl('https://twitter.com/MetaEraHK')"
					>
						<img
							src="https://zy.metaera.media/assets/images/event/12.png"
							alt=""
						/>
					</div>
					<div
						class="pd_bottom_box_3_img_1 pointer"
						@click="showUrl('https://t.me/MetaEraHK')"
					>
						<img
							src="https://zy.metaera.media/assets/images/event/11.png"
							alt=""
						/>
					</div>
					<div
						class="pd_bottom_box_3_img_1 pointer"
						@click="showUrl('https://www.linkedin.com/company/95041236/')"
					>
						<img
							src="https://zy.metaera.media/assets/images/event/8.png"
							alt=""
						/>
					</div>
					<div
						class="pd_bottom_box_3_img_1 pointer"
						@click="showUrl('https://www.youtube.com/@MetaEraHK')"
					>
						<img
							src="https://zy.metaera.media/assets/images/event/50.png"
							alt=""
						/>
					</div>
				</div>
			</div>

			<div class="pd_bottom_box_4">
				<div class="pd_bottom_box_4_1">CONTACT US</div>
				<div class="pd_bottom_box_4_2 pointer" @click="sendEmail"
					>event@metaera.hk</div
				>
				<div class="pd_bottom_box_4_3"></div>
			</div>
			<div class="pd_bottom_box">
				<div class="pd_bottom_box_1">
					<div
						class="pd_bottom_box_1_1 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_about',
							})
						"
						>ABOUT</div
					>
					<div
						class="pd_bottom_box_1_2 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_speaker',
							})
						"
						>SPEAKER</div
					>
				</div>
				<div class="pd_bottom_box_2">
					<div
						class="pd_bottom_box_1_1 pointer"
						@click="
							$router.push({
								path: '/polkadot2024_partner',
							})
						"
						>PARTNER</div
					>
					<div
						class="pd_bottom_box_1_2 pointer"
						@click="showUrl('https://lu.ma/hszom0hw')"
						>PARTICIPATE</div
					>
				</div>
			</div>
			<div class="pd_bottom_line"></div>
			<div class="pd_bottom_company"> Copyright © 2024. Made by MetaEra </div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	created() {},
	data() {
		return {
			currentIndex: 0,
		};
	},
	methods: {
		sendEmail() {
			const email = "event@metaera.hk";
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		showUrl(url) {
			window.open(url, "");
		},
	},
	watch: {},
};
</script>

<style scoped>
.pd_bottom_pc {
	display: contents;
	background: white;
}
.pd_bottom_mobile {
	display: none;
	background: white;
}
.pd_bottom {
	background: white;
	margin-left: 40px;
	margin-right: 40px;
	padding-left: 55px;
	padding-top: 61px;
	padding-right: 55px;
	padding-bottom: 50px;
	margin-bottom: 20px;
	border-radius: 32px;
	opacity: 1;
	background: #141414;
}
.pd_bottom_logo {
	width: 288px;
	height: 53px;
}
.pd_bottom_logo img {
	width: 100%;
	height: 100%;
}
.pd_bottom_time {
	margin-top: 53px;
	display: flex;
}

.pd_bottom_time_1 {
	width: 50%;
	font-family: DINPro-Regular, DINPro;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_bottom_time_2 {
	width: 50%;
	margin-left: 60px;
	font-family: DINPro-Regular, DINPro;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_bottom_box {
	display: flex;
	margin-top: 21px;
	margin-bottom: 70px;
}
.pd_bottom_box_1 {
	width: 30%;
}
.pd_bottom_box_2 {
	width: 20%;
}
.pd_bottom_box_3 {
	margin-left: 70px;
	width: 30%;
}
.pd_bottom_box_4 {
	margin-top: 0px;
	width: 20%;
}
.pd_bottom_box_1_1,
.pd_bottom_box_1_2 {
	margin-top: 40px;
	font-family: DINPro-Bold;
	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.pd_bottom_box_line {
	margin-top: 34px;
	width: 1px;
	height: 95px;
	opacity: 1;

	background: #d8d8d8;
}

.pd_bottom_box_3_img {
	display: flex;
	/* justify-content: space-between; */
}

.pd_bottom_box_3_img_1 {
	margin-top: 30px;
	margin-right: 20px;
	width: 40px;
	height: 40px;
}

.pd_bottom_box_3_img_1 img {
	width: 100%;
	height: 100%;
}

.pd_bottom_box_4_1 {
	margin-top: 30px;
	font-family: DINPro-Regular, DINPro;
	font-size: 22px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_bottom_line {
	height: 1px;
	opacity: 1;

	background: #d8d8d8;
}
.pd_bottom_company {
	height: 80px;
	opacity: 1;

	font-family: DINPro-Bold;
	font-size: 22px;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
	/* display: flex; */
	align-items: center;
	text-align: center;
	align-content: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.pd_bottom_box_4_3 {
	height: 2px;
	width: 180px;
	background: white;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.pd_bottom_pc {
		display: none;
	}
	.pd_bottom_mobile {
		display: contents;
	}
	.pd_bottom {
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 10px;
		padding-left: 26px;
		padding-top: 28px;
		padding-right: 26px;
		padding-bottom: 10px;
		border-radius: 20px;
		opacity: 1;
		background: #141414;
	}
	.pd_bottom_logo {
		width: 170px;
		height: 30px;
	}
	.pd_bottom_logo img {
		width: 100%;
		height: 100%;
	}
	.pd_bottom_time {
		margin-top: 30px;
		display: flex;
	}

	.pd_bottom_time_1 {
		font-family: DINPro-Regular, DINPro;
		font-size: 16px;
		font-weight: normal;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.pd_bottom_time_2 {
		margin-left: 20px;
		font-family: DINPro-Regular, DINPro;
		font-size: 16px;
		font-weight: normal;
		line-height: 20px;
		text-align: left;
		display: flex;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}

	.pd_bottom_box {
		display: flex;
		margin-top: 24px;
		margin-bottom: 30px;
	}
	.pd_bottom_box_1 {
		width: 50%;
	}
	.pd_bottom_box_2 {
		width: 20%;
	}
	.pd_bottom_box_3 {
		margin-left: 0px;
		margin-top: 20px;
		width: 100%;
	}
	.pd_bottom_box_4 {
		margin-top: 40px;
		width: 100%;
	}
	.pd_bottom_box_1_1,
	.pd_bottom_box_1_2 {
		margin-top: 20px;
		font-family: DINPro-Bold;
		font-size: 20px;
		font-weight: bold;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.pd_bottom_box_line {
		margin-top: 34px;
		width: 1px;
		height: 95px;
		opacity: 1;

		background: #d8d8d8;
	}

	.pd_bottom_box_3_img {
		display: flex;
	}

	.pd_bottom_box_3_img_1 {
		margin-top: 20px;
		margin-right: 10%;
		width: 15%;
		height: 15%;
	}

	.pd_bottom_box_3_img_1 img {
		width: 100%;
		height: 100%;
	}

	.pd_bottom_box_4_1 {
		margin-top: 50px;
		font-family: D-DIN;
		font-size: 16px;
		font-weight: normal;
		line-height: 18px;
		display: flex;
		align-items: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.pd_bottom_box_4_2 {
		margin-top: 20px;
		font-size: 20px;
		font-weight: bold;
		line-height: 30px;
		text-align: center;
		display: flex;
		align-items: center;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}

	.pd_bottom_box_4_3 {
		height: 2px;
		width: 180px;
		background: white;
	}
	.pd_bottom_line {
		height: 1px;
		opacity: 1;

		background: #d8d8d8;
	}
	.pd_bottom_company {
		height: 80px;
		opacity: 1;

		font-family: DINPro-Bold;
		font-size: calc(100vw * 18 / 375);
		font-weight: bold;
		line-height: calc(100vw * 22 / 375);
		text-align: center;
		/* display: flex; */
		align-items: center;
		text-align: center;
		align-content: center;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
}
</style>
