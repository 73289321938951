var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd_bottom"},[_c('div',{staticClass:"pd_bottom_pc"},[_c('div',{staticClass:"pd_bottom_logo pointer",on:{"click":function($event){return _vm.$router.push({
					path: '/',
				})}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/34.png","alt":""}})]),_vm._m(0),_c('div',{staticClass:"pd_bottom_box"},[_c('div',{staticClass:"pd_bottom_box_1"},[_c('div',{staticClass:"pd_bottom_box_1_1 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_about',
						})}}},[_vm._v(" ABOUT ")]),_c('div',{staticClass:"pd_bottom_box_1_2 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_speaker',
						})}}},[_vm._v(" SPEAKER ")])]),_c('div',{staticClass:"pd_bottom_box_2"},[_c('div',{staticClass:"pd_bottom_box_1_1 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_partner',
						})}}},[_vm._v(" PARTNER ")]),_c('div',{staticClass:"pd_bottom_box_1_2 pointer",on:{"click":function($event){return _vm.showUrl('https://lu.ma/hszom0hw')}}},[_vm._v("PARTICIPATE")])]),_c('div',{staticClass:"pd_bottom_box_line"}),_c('div',{staticClass:"pd_bottom_box_3"},[_c('div',{staticClass:"pd_bottom_box_4_1"},[_vm._v("SUBSCRIBE TO NEWS")]),_c('div',{staticClass:"pd_bottom_box_3_img"},[_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://twitter.com/MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/12.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://t.me/MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/11.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://www.linkedin.com/company/95041236/')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/8.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://www.youtube.com/@MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/50.png","alt":""}})])])]),_c('div',{staticClass:"pd_bottom_box_4"},[_c('div',{staticClass:"pd_bottom_box_4_1"},[_vm._v("CONTACT US")]),_c('div',{staticClass:"pd_bottom_box_4_1 pointer",on:{"click":_vm.sendEmail}},[_vm._v(" event@metaera.hk ")]),_c('div',{staticClass:"pd_bottom_box_4_3"})])]),_c('div',{staticClass:"pd_bottom_line"}),_c('div',{staticClass:"pd_bottom_company"},[_vm._v(" Copyright © 2024. Made by MetaEra ")]),_c('div',{staticClass:"pd_bottom_line"})]),_c('div',{staticClass:"pd_bottom_mobile"},[_c('div',{staticClass:"pd_bottom_logo",on:{"click":function($event){return _vm.$router.push({
					path: '/',
				})}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/34.png","alt":""}})]),_vm._m(1),_c('div',{staticClass:"pd_bottom_box_3"},[_c('div',{staticClass:"pd_bottom_box_4_1"},[_vm._v("SUBSCRIBE TO NEWS")]),_c('div',{staticClass:"pd_bottom_box_3_img"},[_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://twitter.com/MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/12.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://t.me/MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/11.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://www.linkedin.com/company/95041236/')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/8.png","alt":""}})]),_c('div',{staticClass:"pd_bottom_box_3_img_1 pointer",on:{"click":function($event){return _vm.showUrl('https://www.youtube.com/@MetaEraHK')}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/event/50.png","alt":""}})])])]),_c('div',{staticClass:"pd_bottom_box_4"},[_c('div',{staticClass:"pd_bottom_box_4_1"},[_vm._v("CONTACT US")]),_c('div',{staticClass:"pd_bottom_box_4_2 pointer",on:{"click":_vm.sendEmail}},[_vm._v("event@metaera.hk")]),_c('div',{staticClass:"pd_bottom_box_4_3"})]),_c('div',{staticClass:"pd_bottom_box"},[_c('div',{staticClass:"pd_bottom_box_1"},[_c('div',{staticClass:"pd_bottom_box_1_1 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_about',
						})}}},[_vm._v("ABOUT")]),_c('div',{staticClass:"pd_bottom_box_1_2 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_speaker',
						})}}},[_vm._v("SPEAKER")])]),_c('div',{staticClass:"pd_bottom_box_2"},[_c('div',{staticClass:"pd_bottom_box_1_1 pointer",on:{"click":function($event){return _vm.$router.push({
							path: '/polkadot2024_partner',
						})}}},[_vm._v("PARTNER")]),_c('div',{staticClass:"pd_bottom_box_1_2 pointer",on:{"click":function($event){return _vm.showUrl('https://lu.ma/hszom0hw')}}},[_vm._v("PARTICIPATE")])])]),_c('div',{staticClass:"pd_bottom_line"}),_c('div',{staticClass:"pd_bottom_company"},[_vm._v(" Copyright © 2024. Made by MetaEra ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd_bottom_time"},[_c('div',{staticClass:"pd_bottom_time_1"},[_vm._v("Polkadot Decoded Asia 2024")]),_c('div',{staticClass:"pd_bottom_time_2"},[_vm._v("Time： Sep 16 - Sep 17, 2024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd_bottom_time"},[_c('div',{staticClass:"pd_bottom_time_1"},[_vm._v("Polkadot "),_c('br'),_vm._v("Decoded Asia 2024")]),_c('div',{staticClass:"pd_bottom_time_2"},[_vm._v("Time： "),_c('br'),_vm._v("Sep 16 - Sep 17, 2024")])])
}]

export { render, staticRenderFns }